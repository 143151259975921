export const plansHorsAfrique = {
  standard: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 295,
    priceId: "price_1R9im5Bs7eYDY6jWgWQDZb8W",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
  professionnel: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 590,
    priceId: "price_1R9im5Bs7eYDY6jWW9u4LsKY",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
  enterprise: {
    name: "Annuel",
    periode: "an",
    price: 1180,
    priceId: "price_1R9im5Bs7eYDY6jWxr9WDF7Z",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
};

export const africPlans = {
  standard: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 105,
    priceId: "price_1R9hvnBs7eYDY6jWSoMOghiJ",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
  professionnel: {
    name: "Semestriel",
    periode: "Semestre",
    price: 210,
    priceId: "price_1R9hvnBs7eYDY6jWgArNqV8a",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
  enterprise: {
    name: "Annuel",
    periode: "an",
    price: 420,
    priceId: "price_1R9hvnBs7eYDY6jWxdubJ06s",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
};

export const companyPlans = {
  standard: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 105,
    priceId: "price_1R9jF4Bs7eYDY6jWagNWjjSD",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
  professionnel: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 295,
    priceId: "price_1R9jG1Bs7eYDY6jWIpYWlJHj",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },

  enterprise: {
    name: "Trimestriel",
    periode: "Trimestre",
    price: 500,
    priceId: "price_1R9jGaBs7eYDY6jWphYqk5IM",
    features: [
      "Tous les avantages Professionnel",
      "Accès aux webinaires exclusifs",
      "Réseau professionnel privilégié",
      "Participation aux événements",
      "Accès VIP aux conférences",
    ],
  },
};
