import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "./../../public/images/logo.png";

const TopMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [query, setQuery] = useState("");

  const findNewsHandler = (e) => {
    e.preventDefault();
    navigate(`/actualites/?search=${query}`);
  };

  return (
    <div className="bg-[#00075e] flex z-50 items-center text-white md:text-black md:bg-white pt-2 pb-2">
      <div className=" h-full w-[95%] lg:w-[80%] mx-auto flex justify-center md:justify-between">
        <div className=" hidden md:block ">
          <Link to="/">
            <img
              src={logo}
              alt="image"
              className=" h-[100px] lg:h-[170px] w-[100%] object-cover"
            />
          </Link>
        </div>
        <div className="justify-center flex flex-col items-center h-full">
          <ul className="    hidden md:flex font-semibold text-[#00075e] h-full items-center text-[0.64rem] md:text-[0.8rem] uppercase  space-x-8">
            {/* <li>
              <Link to='/'>Portail</Link>
            </li> */}
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://academieceisna.org/"
                className="text-[#00075e]"
              >
                Formation
              </Link>
            </li>
            <li>
              <Link to="/don" className="text-[#00075e]">
                Don
              </Link>
            </li>
            <li>
              <Link to="/experts">Cartographie</Link>
            </li>

            <li>
              <Link to="/contactez-nous">Contact</Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://communautyceisna.org"
              >
                Communauté
              </Link>
            </li>

            <li>
              <Link to="/revues">Revue</Link>
            </li>
          </ul>
          <div className=" flex mt-3 w-full  justify-end">
            <form
              onSubmit={findNewsHandler}
              className=" hidden md:flex relative  "
            >
              <input
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Rechercher un article"
                className=" w-[100%] text-sm py-2 pl-2 pr-4 border-[1px] border-[#b3b3b3]"
                type="text "
              />
              <button type="submit" className=" absolute top-[20%] right-[2px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
