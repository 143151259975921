import React from "react";
import { Users, Building2, Briefcase } from "lucide-react";

const interviews = [
  {
    id: "1",
    number: "01",
    title:
      "Le Rôle Stratégique du Numérique dans le Renforcement des Systèmes de Santé au Niger et en Afrique",
    description:
      "Explorer les initiatives numériques clés au Niger et leur impact sur le renforcement des systèmes de santé, avec une vision élargie aux enjeux et solutions pour l'Afrique.",
  },
  {
    id: "2",
    number: "02",
    title:
      "Éthique et IA en Santé – Innover de manière responsable pour l'Afrique et le Monde",
    description:
      "Cette présentation mettra en lumière les solutions possibles pour intégrer l'IA de manière responsable, avec un focus sur l'impact en Afrique et au niveau mondial.",
  },
  {
    id: "3",
    number: "03",
    title:
      "Santé Numérique et Partenariats Stratégiques : Construire un Écosystème Durable au Tchad et en Afrique",
    description:
      "Mettre en lumière les partenariats stratégiques nécessaires pour construire un écosystème durable de santé numérique au Tchad, avec une vision élargie à l'Afrique.",
  },
];

const profiles = [
  {
    icon: <Users className="w-12 h-12 text-indigo-600" />,
    title: "Experts et professionnels de santé spécialisés",
    description:
      "Ce sont des professionnels ou chercheurs ayant une expertise reconnue dans le domaine abordé. Ils jouent un rôle central en partageant des insights, des découvertes, ou des tendances via des présentations, panels ou keynotes.",
  },
  {
    icon: <Building2 className="w-12 h-12 text-indigo-600" />,
    title: "Représentants institutionnels",
    description:
      "Ces participants proviennent de structures publiques ou gouvernementales. Leur rôle est souvent d'établir des cadres réglementaires, de promouvoir des politiques publiques, ou de représenter des gouvernements ou des institutions internationales.",
  },
  {
    icon: <Briefcase className="w-12 h-12 text-indigo-600" />,
    title: "Acteurs du secteur privé et ONG",
    description:
      "Les ONG sont des acteurs indépendants qui défendent des causes sociales, environnementales, ou humanitaires. Ce groupe inclut aussi les entreprises et les entrepreneurs qui jouent un rôle clé dans l'innovation, l'investissement.",
  },
];

function Interviews() {
  return (
    <div className=" w-[95%] mb-10 mx-auto mt-6  grid-cols-1 grid lg:grid-cols-2 ">
      <div className="px-4 py-4">
        <div className="grid gap-8">
          {interviews.map((interview) => (
            <div
              key={interview.id}
              className="bg-white rounded-lg shadow-md p-8 transition-transform hover:scale-[1.02]"
            >
              <div className="flex flex-col md:flex-row items-start gap-6">
                <div className="bg-gray-100 px-6 py-4 rounded-lg">
                  <span className="text-5xl font-bold text-indigo-900">
                    {interview.number}
                  </span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-indigo-900 mb-2">
                    {interview.title}
                  </h3>
                  <p className="text-gray-600  italic">
                    {interview.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="  px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold text-start text-indigo-900  mb-4">
            Profils Attendus
          </h2>

          <div className=" h-[400px]">
            <img
              className=" w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=3538&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>

          <div className="grid md:grid-cols-3 mt-5 gap-4">
            {profiles.map((profile, index) => (
              <div
                key={index}
                className=" transition-transform hover:scale-[1.02]"
              >
                <div className="flex flex-col items-center text-justify">
                  <h3 className="text-xl text-start font-semibold text-indigo-900 mt-4 mb-3">
                    {profile.title}
                  </h3>
                  <div className="bg-gray-200 rounded-lg  p-4 ">
                    <p className="text-gray-600">{profile.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interviews;
