import React, { useEffect, useState } from "react";
import { Heart, Download, Share2 } from "lucide-react";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/TextLayer.css";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { useDocument } from "../hooks/useDocument";
import Spinner from "../componants/utilities/Spinner";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

// Configuration du worker PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

function RevueDetails() {
  let { id } = useParams();
  const [tab, setTab] = useState(0);

  const { docResponse } = useDocument("publications", id);

  const { document, isPending } = docResponse;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [liked, setLiked] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     if (event.deltaY > 0 && pageNumber < numPages) {
  //       setPageNumber((prev) => prev + 1);
  //     } else if (event.deltaY < 0 && pageNumber > 1) {
  //       setPageNumber((prev) => prev - 1);
  //     }
  //   };

  //   window.addEventListener("wheel", handleScroll);
  //   return () => window.removeEventListener("wheel", handleScroll);
  // }, [pageNumber, numPages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight" && pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      } else if (event.key === "ArrowLeft" && pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [pageNumber, numPages]);

  // Vérifier si le document est liké au chargement
  useEffect(() => {
    const storedLikes =
      JSON.parse(localStorage.getItem("likedDocuments")) || [];
    setLiked(storedLikes.includes(document?.id));
  }, [document?.id]);

  // Ajouter ou supprimer des favoris
  const toggleLike = () => {
    const storedLikes =
      JSON.parse(localStorage.getItem("likedDocuments")) || [];
    let updatedLikes;

    if (liked) {
      updatedLikes = storedLikes.filter((id) => id !== document.id);
    } else {
      updatedLikes = [...storedLikes, document.id];
    }

    localStorage.setItem("likedDocuments", JSON.stringify(updatedLikes));
    setLiked(!liked);
  };

  // Fonction de partage (Web Share API)
  const shareDocument = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Regarde ce document intéressant !",
          url: document.pdfUrl, // Assurez-vous que c'est une URL accessible
        });
      } catch (error) {
        console.error("Erreur de partage :", error);
      }
    } else {
      alert("Le partage Web n'est pas supporté sur ce navigateur.");
    }
  };

  const printDocument = () => {
    const pdfUrl = document.pdfUrl; // Assure-toi que l'URL est valide

    if (!pdfUrl) {
      alert("Aucun document disponible.");
      return;
    }

    window.open(pdfUrl, "_blank");
  };

  if (isPending) {
    return (
      <div className=" items-center flex justify-center h-[70vh] w-[95%] md:w-[70%] lg:w-[50%] mx-auto">
        <Spinner loading={isPending} />
      </div>
    );
  }

  return (
    <main className="bg-gray-100 mt-0 lg:mt-12">
      {/* Section En-tête */}
      <div className="bg-white p-6 shadow-sm">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row gap-6">
            {/* Image */}
            <div className=" w-52  h-72 bg-gray-200 rounded overflow-hidden">
              <img
                src={document?.image}
                alt="UNESCO Éducation"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Informations sur le document */}
            <div className="flex-1">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                {document?.title}
              </h1>

              <div className="space-y-2 text-sm">
                <p>
                  <span className="text-gray-600">Auteur :</span>{" "}
                  {document?.author}
                </p>

                <p>
                  <span className="text-gray-600">ISBN :</span> {document?.isbn}
                </p>
                <p>
                  <span className="text-gray-600">Collation :</span>{" "}
                  {document?.pages} pages
                </p>
                <p>
                  <span className="text-gray-600">Langue :</span>{" "}
                  {document?.language}
                </p>
                <p>
                  <span className="text-gray-600">Année de publication :</span>{" "}
                  {document?.year}
                </p>
                <p>
                  <span className="text-gray-600">Type de document :</span>{" "}
                  {document?.documentType}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Boutons d'action */}
      <div className="bg-white border-t border-b">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between px-6 py-2">
            <div className="flex space-x-8">
              <button
                onClick={toggleLike}
                className={`flex items-center gap-2 text-gray-600 hover:text-blue-600  ${
                  liked ? "text-red-500" : "text-gray-600"
                }`}
              >
                <Heart size={20} />
                <span>Favoris</span>
              </button>

              <button
                onClick={printDocument}
                className="flex items-center gap-2 text-gray-600 hover:text-blue-600"
              >
                <Download size={20} className="mb-1" />
                Télécharger
              </button>
              <button
                onClick={shareDocument}
                className="flex items-center gap-2 text-gray-600 hover:text-blue-600"
              >
                <Share2 size={20} />
                <span>Partager</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Onglets */}
      <div className="bg-white border-b">
        <div className="max-w-6xl mx-auto">
          <div className="flex px-6">
            <button
              onClick={() => setTab(0)}
              className={`px-4 py-3  ${
                tab === 0
                  ? "border-blue-600 text-blue-600 border-b-2"
                  : "text-gray-600 hover:text-gray-800"
              }   font-medium`}
            >
              TEXTE COMPLET
            </button>
            <button
              onClick={() => setTab(1)}
              className={`px-4 py-3  ${
                tab === 1
                  ? "border-blue-600 text-blue-600 border-b-2"
                  : "text-gray-600 hover:text-gray-800"
              }   font-medium`}
            >
              FICHE
            </button>
          </div>
        </div>
      </div>
      <div className=" relative max-w-6xl mx-auto  p-6   ">
        {document && document?.pdfUrl && (
          <>
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              animate={{
                x: tab === 0 ? "0%" : "-100%",
                opacity: tab === 0 ? 1 : 0,
              }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="max-w-xs overflow-hidden  block md:hidden  sm:max-w-md md:max-w-lg w-full"
            >
              <div className="flex w-full flex-col items-start">
                <Document
                  file={document.pdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="flex w-full justify-center"
                  loading={
                    <div className="flex flex-col items-center justify-center h-64">
                      <svg
                        className="animate-spin h-8 w-8 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                      <p className="text-gray-600 mt-2">
                        Chargement du document...
                      </p>
                    </div>
                  }
                >
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer
                    renderAnnotationLayer
                    className="shadow-lg w-full "
                    scale={0.5}
                  />
                </Document>

                <div className="mt-4 z-40 justify-center items-center  max-w-xl w-full flex gap-4">
                  <button
                    onClick={prevPage}
                    disabled={pageNumber === 1}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                  >
                    Précédent
                  </button>
                  <span>
                    Page {pageNumber} / {numPages}
                  </span>
                  <button
                    onClick={nextPage}
                    disabled={pageNumber === numPages}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              animate={{
                x: tab === 0 ? "0%" : "-100%",
                opacity: tab === 0 ? 1 : 0,
              }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="max-w-xs overflow-hidden hidden md:block  sm:max-w-md md:max-w-lg w-full"
            >
              <div className="flex w-full flex-col items-start">
                <Document
                  file={document.pdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="flex w-full justify-center"
                  loading={
                    <div className="flex flex-col items-center justify-center h-64">
                      <svg
                        className="animate-spin h-8 w-8 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                      <p className="text-gray-600 mt-2">
                        Chargement du document...
                      </p>
                    </div>
                  }
                >
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer
                    renderAnnotationLayer
                    className="shadow-lg w-full "
                    scale={0.8}
                  />
                </Document>

                <div className="mt-4 z-40 justify-center items-center  max-w-xl w-full flex gap-4">
                  <button
                    onClick={prevPage}
                    disabled={pageNumber === 1}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                  >
                    Précédent
                  </button>
                  <span>
                    Page {pageNumber} / {numPages}
                  </span>
                  <button
                    onClick={nextPage}
                    disabled={pageNumber === numPages}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
        <div className=" top-0 py-4 absolute p-6 left-0">
          <motion.div
            initial={{ x: "100%", opacity: 0 }}
            animate={{
              x: tab === 1 ? "0%" : "100%",
              opacity: tab === 1 ? 1 : 0,
            }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={`bg-white  shadow-md p-6 w-full md:w-[500px] max-w-2xl ${
              tab === 0 && " hidden"
            }`}
          >
            <div className="mt-4 text-gray-800">
              <h2 className="text-xl font-semibold">Fiche</h2>
              <div className="mt-3 space-y-2">
                <p>
                  <strong>Titre :</strong>{" "}
                  <span className="text-blue-800 underline">
                    {document?.title}
                  </span>
                </p>

                <p>
                  <strong>Type de matériel :</strong>
                  {document?.documentType}
                </p>
                <p>
                  <strong>Année de publication :</strong>{" "}
                  <a className="text-blue-800 underline">{document?.year}</a>
                </p>
                <p>
                  <strong>Édition :</strong>{" "}
                  <span className="text-blue-800 underline">
                    {document?.edition}
                  </span>
                </p>
                <p>
                  <strong>Pays de publication :</strong>{" "}
                  {document?.publisContry}
                </p>
                <p>
                  <strong>Langue :</strong> {document?.language}
                </p>

                <p>
                  <strong>Auteur :</strong>{" "}
                  <span className="text-blue-800 underline">
                    {document?.author}
                  </span>
                </p>

                <p>
                  <strong>Code auteur :</strong> {document?.authorCode}
                </p>

                <p>
                  <strong>Type de licence :</strong>{" "}
                  <span className="text-blue-800 underline">
                    {document?.licenceType}
                  </span>
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </main>
  );
}

export default RevueDetails;
