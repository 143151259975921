import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../firebase/config";
import { toast } from "react-toastify";
import ButtonSpinner from "../../utilities/ButtonSpinner";

const MemberForm = ({
  setDataNaissance,
  handleFocus,
  handleBlur,
  isFocused,
  dataNaissance,
  isPersonel,
}) => {
  const handleChange = (e) => setDataNaissance(e.target.value);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [adresse, setAdresse] = useState("");
  const [rue, setRue] = useState("");
  const [codepostal, setCodepostal] = useState("");
  const [ville, setVille] = useState("");
  const [pays, setPays] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState("");
  const [secteur, setSecteur] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [complementaires, setComplementaires] = useState("");
  const [langues, setLangues] = useState("");
  const [loading, setLoading] = useState(false);

  const minDate = "1950-01-01";
  const maxDate = "2015-12-31";

  const signup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const functions = getFunctions(app);
      const sendSignupEmail = httpsCallable(functions, "sendSignupEmail");

      const result = await sendSignupEmail({
        name,
        dataNaissance,
        email,
        adresse,
        rue,
        codepostal,
        ville,
        pays,
        phone,
        profession,
        secteur,
        complementaires,
        organisation,
        langues,
        type: isPersonel ? "Personne physique" : "Personne Moral",
      });

      if (result.data.success) {
        setLoading(false);
        setEmail("");
        setName("");
        setAdresse("");
        setRue("");
        setCodepostal("");
        setVille("");
        setPays("");
        setPhone("");
        setProfession("");
        setSecteur("");
        setOrganisation("");
        setComplementaires("");
        setLangues("");
        toast.success("Inscription envoyé avec succès!", {
          position: "top-center",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Erreur lors de l'envoi de l'inscription", {
        position: "top-center",
      });
    }
  };

  return (
    <form onSubmit={signup} className=" text-white">
      <div className="mb-4">
        <label htmlFor="">Informations Personnelles</label>
        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="name"
            name="name"
            placeholder="Nom complet"
            className="w-full p-2 border border-gray-300 text-[#000]   rounded focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />
          <div className="relative">
            <input
              required
              type="date"
              id="date"
              name="date"
              min={minDate}
              max={maxDate}
              className={`${
                isFocused || dataNaissance !== ""
                  ? " text-[#000]"
                  : "text-[#fff]"
              } w-full  p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#5bc199]`}
              value={dataNaissance}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!isFocused && !dataNaissance && (
              <span className="absolute top-2 left-3 text-gray-400 pointer-events-none">
                date de naissance
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="">Coordonnées</label>
        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={adresse}
            onChange={(e) => setAdresse(e.target.value)}
            type="text"
            id="adresse"
            name="adresse"
            placeholder="Adresse"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />

          <input
            value={rue}
            onChange={(e) => setRue(e.target.value)}
            type="text"
            id="rue"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
            placeholder="N° et nom de la rue"
          />
        </div>

        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={codepostal}
            onChange={(e) => setCodepostal(e.target.value)}
            type="text"
            id="Code-postal"
            name="Code-postal"
            placeholder="Code postal"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />

          <input
            required
            value={ville}
            onChange={(e) => setVille(e.target.value)}
            type="text"
            id="Ville"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
            placeholder="Ville"
          />
        </div>

        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={pays}
            onChange={(e) => setPays(e.target.value)}
            type="text"
            id="Pays"
            name="Pays"
            placeholder="Pays"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />

          <input
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            id="Téléphone"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
            placeholder="Téléphone"
          />
        </div>

        <input
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          id="E-mail"
          className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          placeholder="E-mail"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Situation Professionnelle</label>
        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            type="text"
            id="Profession-Occupation"
            name="Profession-Occupation"
            placeholder="Profession / Occupation"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />

          <input
            required
            value={secteur}
            onChange={(e) => setSecteur(e.target.value)}
            type="text"
            id={`Secteur d'activité`}
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
            placeholder={`Secteur d'activité`}
          />
        </div>

        <input
          required
          value={organisation}
          onChange={(e) => setOrganisation(e.target.value)}
          type="text"
          id={`Organisation`}
          name={`Organisation`}
          placeholder={`Nom de l'employeur / Organisation`}
          className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Situation Professionnelle</label>
        <div className="   grid mb-3 grid-cols-2 gap-3">
          <input
            required
            value={complementaires}
            onChange={(e) => setComplementaires(e.target.value)}
            type="text"
            id="complémentaires"
            name="Complémentaires"
            placeholder="Renseignements Complémentaires"
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
          />

          <input
            required
            value={langues}
            onChange={(e) => setLangues(e.target.value)}
            type="text"
            id={`Langues parlées`}
            className="w-full p-2 border border-gray-300 rounded text-[#000]  focus:outline-none focus:ring-2 focus:ring-[#5bc199]"
            placeholder={`Langues parlées`}
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-[#5bc199] text-white p-2 rounded"
      >
        {loading ? <ButtonSpinner loading={loading} /> : "Inscription"}
      </button>
    </form>
  );
};

export default MemberForm;
