import React from "react";

const HeaderTitle = ({ children }) => {
  return (
    <span className="  text-[1.5rem] md:text-[2.5rem] font-bold text-[#00075e] ">
      {children}
    </span>
  );
};

export default HeaderTitle;
