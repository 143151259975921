export function GuestOfHonor({
  name,
  title,
  subtitle,
  imageUrl,
  description,
  signature,
}) {
  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-[#1a1f4d] text-white p-6 rounded-lg">
          <h1 className="text-4xl font-bold mb-8 text-center">
            Invité
            <br />
            d'Honneur
          </h1>

          <div className="mb-8">
            <img
              src={imageUrl}
              alt={name}
              className="w-full h-[450px] object-top object-cover  rounded-lg shadow-lg"
            />
          </div>

          <div className="space-y-4 text-center">
            <h2 className="text-2xl font-semibold">Son Excellence</h2>
            <h3 className="text-xl">{name}</h3>
            <p className="text-lg">{title}</p>
            <p className="text-lg">{subtitle}</p>
          </div>
        </div>

        {/* Right Column - Description */}
        <div className="flex items-center">
          <div className="space-y-6">
            <p className="text-gray-700 text-lg italic leading-relaxed">
              {description}
            </p>
            {signature && (
              <p className="text-right text-gray-600 font-semibold">
                {signature}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
