import { Check } from "lucide-react";
import React from "react";

const PlanCard = ({ selectedPlan, setSelectedPlan, planKey, plan }) => {
  return (
    <div
      className={`rounded-2xl p-8 ${
        selectedPlan === planKey
          ? "bg-white shadow-xl transform -translate-y-2"
          : "bg-blue-800/30 text-white"
      }`}
    >
      <h3
        className={`text-2xl font-bold mb-4 ${
          selectedPlan === planKey ? "text-blue-900" : "text-white"
        }`}
      >
        {plan.name}
      </h3>
      <p
        className={`text-3xl font-bold mb-6 ${
          selectedPlan === planKey ? "text-blue-900" : "text-white"
        }`}
      >
        {plan.price}€<span className="text-sm">/{plan.periode}</span>
      </p>
      <ul className="space-y-4 mb-8">
        {plan.features.map((feature, index) => (
          <li planKey={index} className="flex items-center">
            <Check
              className={`w-5 h-5 mr-2 ${
                selectedPlan === planKey ? "text-blue-500" : "text-blue-300"
              }`}
            />
            <span
              className={
                selectedPlan === planKey ? "text-gray-600" : "text-blue-100"
              }
            >
              {feature}
            </span>
          </li>
        ))}
      </ul>
      <button
        onClick={() => setSelectedPlan(planKey)}
        className={`w-full py-3 px-6 rounded-lg font-semibold transition-all ${
          selectedPlan === planKey
            ? "bg-blue-600 text-white hover:bg-blue-700"
            : "bg-blue-400/20 text-white hover:bg-blue-400/30"
        }`}
      >
        {selectedPlan === planKey ? "Plan Sélectionné" : "Sélectionner ce Plan"}
      </button>
    </div>
  );
};

export default PlanCard;
