import { motion } from "framer-motion";

function ProgressBar({ step }) {
  const totalSteps = 4;
  const progress = (step / totalSteps) * 100;

  return (
    <div className="w-full mb-8">
      <div className="relative pt-1">
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-800/30">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
