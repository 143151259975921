import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";

export function usePaypalScription() {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  useEffect(() => {
    if (CLIENT_ID) {
      paypalDispatch({
        type: "resetOptions",
        value: {
          "client-id": CLIENT_ID,
          currency: "EUR",
          intent: "subscription",
          vault: true,
          debug: true,
        },
      });

      paypalDispatch({ type: "setLoadingStatus", value: "pending" });
    }
  }, [CLIENT_ID, paypalDispatch]);

  return { isPending };
}
