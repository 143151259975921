import React, { useState } from "react";
import MapComponent from "./../componants/Experts/MapComponent";
import { mockUsers } from "../data/experts";

function Expert() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(mockUsers);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredUsers(
      mockUsers.filter((user) => user.country.toLowerCase().includes(value))
    );
  };

  return (
    <main className=" bg-[#f0fcfa] pt-4">
      <div className=" flex">
        <div className=" hidden lg:block px-2 pt-12 lg:w-[20%]">
          <div>
            <div className=" relative">
              <spanb className=" ml-2 absolute top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6 text-[#00075e]"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              </spanb>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Rechercher par pays"
                className=" w-full  rounded-[10px] pl-6 pr-2 border-[10px] border-[#003f97]"
              />
            </div>
            <div className=" relative">
              <div className=" map-scroll">
                <h2 className=" text-[0.9rem]  font-medium text-[#00075e] py-2 uppercase">
                  Ceisna Expert
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full lg:w-[80%]">
          <MapComponent filteredUsers={filteredUsers} />
        </div>
      </div>
    </main>
  );
}

export default Expert;
