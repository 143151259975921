import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBipctzsaI9I_NiMmCFAqbhWSa162DpNmg",
  authDomain: "ceisna-admin.firebaseapp.com",
  projectId: "ceisna-admin",
  storageBucket: "ceisna-admin.appspot.com",
  messagingSenderId: "616717098281",
  appId: "1:616717098281:web:8bd43f6dbfeeacf0e42b38",
};

// inti firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, app };
