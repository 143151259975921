import { useNavigate } from "react-router-dom";

const StripeSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-[70vh] bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-sm rounded-lg p-8 max-w-md text-center">
        <svg
          className="w-16 h-16 text-[#0c40b7] mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.25 12a9.75 9.75 0 1119.5 0 9.75 9.75 0 01-19.5 0zm10.47-2.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06L11.25 11.31l4.47 4.47a.75.75 0 101.06-1.06l-5-5z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-2xl font-bold text-gray-800 mt-4">
          Paiement réussi !
        </h1>
        <p className="text-gray-600 mt-2">
          Merci pour votre paiement. Votre transaction a été traitée avec
          succès.
        </p>
        <button
          onClick={() => navigate("/")}
          className="mt-6 bg-[#0c40b7] text-white px-6 py-2 rounded-full shadow-md hover:bg-[#0c3fb7c1]"
        >
          Retour à l'accueil
        </button>
      </div>
    </div>
  );
};

export default StripeSuccess;
