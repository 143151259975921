import { useEffect, useState } from "react";
import credit from "../../public/images/credicarte.svg";
import paypal from "../../public/images/paypal.svg";
import cheque from "../../public/images/discover.svg";
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import DonationForm from "./DonationForm";
import { useFireStore } from "../../hooks/useFireStore";
import { toast } from "react-toastify";
import Spinner from "../utilities/Spinner";

const stripePromise = loadStripe(
  "pk_live_51QUTZ0Bs7eYDY6jWNl1tVuJQm61qBRaAQJJUsFsFAoKzcZKJFdcMmy4wkPXTX7noRU40IeiptZ9315Bng0GzH4ib00waXAnybJ"
);

const DonationOption = () => {
  const { addDocument } = useFireStore("donation_users");
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMonth, setIsMonth] = useState(false);
  const [amount, setAmout] = useState(220);
  const [payAmount, setPayAmount] = useState(null);
  const [subsAmount, setSubsAmount] = useState(null);
  const [paiment, setPaiment] = useState("credit-carte");
  const [productId, setProductId] = useState("P-1VS037580U0373712M5MW2UQ");
  const [champError, setChampError] = useState("");

  const [email, setEmail] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [pays, setPays] = useState("");
  const [telephone, setTelephone] = useState("");

  useEffect(() => {
    if (payAmount !== null) {
      setAmout(payAmount);
    }
  }, [payAmount]);

  const [errors, setErrors] = useState({
    email: false,
    prenom: false,
    nom: false,
    adresse: false,
    codePostal: false,
    pays: false,
    telephone: false,
  });

  const submitForm = async () => {
    let newErrors = { ...errors };

    // Validation Logic
    newErrors.email = !/\S+@\S+\.\S+/.test(email);
    newErrors.prenom = !prenom.trim();
    newErrors.nom = !nom.trim();
    newErrors.adresse = !adresse.trim();
    newErrors.codePostal = !codePostal.trim();
    newErrors.pays = !pays.trim();
    newErrors.telephone = !telephone || telephone.length < 10;

    setErrors(newErrors);

    // If no errors, call onSubmit from parent component
    if (!Object.values(newErrors).includes(true)) {
      const formData = {
        email,
        prenom,
        nom,
        adresse,
        codePostal,
        pays,
        telephone,
      };
      try {
        setLoading(true);
        await addDocument(formData);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  useEffect(() => {
    const fetchPlans = async () => {
      const functions = getFunctions(app);
      const getSubscriptionPlans = httpsCallable(
        functions,
        "getSubscriptionPlans"
      );

      try {
        const response = await getSubscriptionPlans();
        setPlans(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des plans :", error);
      }
    };

    fetchPlans();
  }, []);

  const handleCheckout = async () => {
    const isSuccessful = await submitForm();

    if (isSuccessful) {
      if (paiment === "credit-carte" && isMonth && payAmount === null) {
        if (!selectedPlan) {
          alert("Veuillez sélectionner un plan d'abonnement.");
          return;
        }

        try {
          const functions = getFunctions(app);
          const createSession = httpsCallable(
            functions,
            "createCheckoutSession"
          );

          const result = await createSession({ priceId: selectedPlan });
          const stripe = await stripePromise;

          const { error } = await stripe.redirectToCheckout({
            sessionId: result.data.sessionId,
          });

          if (error) {
            console.error("Erreur lors de la redirection vers Stripe :", error);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la création de la session de paiement :",
            error
          );
        } finally {
          setLoading(false);
        }
      } else if (paiment === "credit-carte" && !isMonth && payAmount === null) {
        try {
          const functions = getFunctions(app);
          const createSession = httpsCallable(
            functions,
            "createUniqueCheckoutSession"
          );

          const result = await createSession({ amount: amount * 100 });

          const stripe = await stripePromise;

          const { error } = await stripe.redirectToCheckout({
            sessionId: result.data.sessionId,
          });

          if (error) {
            console.error("Error while redirecting to Stripe. :", error);
          }
        } catch (error) {
          console.error("Error while creating the payment session. :", error);
        } finally {
          setLoading(false);
        }
      } else if (paiment === "credit-carte" && !isMonth && payAmount) {
        try {
          const formResult = await submitForm();
          const functions = getFunctions(app);
          const createSession = httpsCallable(
            functions,
            "createUniqueCheckoutSession"
          );

          const result = await createSession({ amount: payAmount * 100 });
          const stripe = await stripePromise;

          const { error } = await stripe.redirectToCheckout({
            sessionId: result.data.sessionId,
          });

          if (error) {
            console.error("Erreur lors de la redirection vers Stripe :", error);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la création de la session de paiement :",
            error
          );
        } finally {
          setLoading(false);
        }
      } else if (paiment === "paypal" && isMonth) {
        await submitForm();
        navigate(
          `/don/abonnement/paypal-chechout/${productId}?amount=${amount}`
        );
        setLoading(false);
      } else if (paiment === "paypal" && !isMonth && payAmount === null) {
        await submitForm();
        navigate(`/don/paypal-chechout/${amount}`);
        setLoading(false);
      } else if (paiment === "paypal" && !isMonth && payAmount) {
        await submitForm();
        navigate(`/don/paypal-chechout/${payAmount}`);
        setLoading(false);
      } else if (paiment === "cheque") {
        try {
          const formData = {
            email,
            prenom,
            nom,
            adresse,
            codePostal,
            pays,
            telephone,
            amount: payAmount === null ? amount : payAmount,
          };
          const functions = getFunctions(app);
          const sendDonationCheque = httpsCallable(
            functions,
            "sendDonationCheque"
          );

          await sendDonationCheque(formData);
          setLoading(false);
          navigate(`/don/cheque-sended/`);
        } catch (error) {
          setLoading(false);
        }
      }
    } else {
      setChampError(
        "Veuillez vous assurer que tous les champs sont correctement remplis."
      );
      setTimeout(() => {
        setChampError("");
      }, 5000);
    }
  };

  return (
    <div
      className="relative py-12 min-h-screen bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1504159506876-f8338247a14a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",

        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundOrigin: "bottom",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-20"></div>

      <div className="relative z-10 flex flex-col items-center justify-center h-full">
        <h1 className=" text-2xl md:text-4xl font-bold text-white text-center mb-6">
          Prêts à tout, tout de suite, tout le temps.
        </h1>
        <p className="text-white text-center text-[1rem] md:text-xl   bg-[#0000004d] p-5 rounded-md  max-w-5xl mb-10">
          Chaque don que vous effectuez contribue directement à soutenir les{" "}
          femmes et enfants les plus vulnérables face aux crises humanitaires.
          Votre générosité permet de fournir une aide vitale, de financer des
          programmes de santé essentiels et de créer des opportunités pour un
          avenir meilleur.
          <br />
          <span className="text-red-500 text-2xl font-bold">
            Agissez maintenant !
          </span>
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6  px-3 2xl:px-0  max-w-5xl w-full">
          <div className="bg-white   rounded-lg shadow-lg">
            <div className=" bg-[#00075e]  py-[0.25rem] flex  justify-center items-center">
              <h2 className=" text-[1rem]  md:text-xl font-bold  text-white text-center">
                1. Mon don
              </h2>
            </div>

            <div className="p-8">
              <div className="mb-4 gap-2  flex">
                <button
                  onClick={() => setIsMonth(false)}
                  className={`px-4  py-2 border border-gray-300 text-sm rounded-md ${
                    isMonth ? "" : "bg-[#38b6ff]  text-white"
                  } `}
                >
                  Je donne une fois
                </button>
                <button
                  onClick={() => {
                    setIsMonth(true);
                    if (subsAmount === null) {
                      setSubsAmount(plans[0]?.unit_amount);
                      setSelectedPlan(plans[0]?.id);
                    }
                  }}
                  className={`px-4  py-2 border border-gray-300 text-sm rounded-md ${
                    isMonth ? "bg-[#38b6ff]  text-white" : ""
                  } `}
                >
                  Je donne chaque mois
                </button>
              </div>
              {isMonth && paiment === "credit-carte" ? (
                <div className=" grid grid-cols-2 gap-2 ">
                  {plans.map((plan) => (
                    <button
                      onClick={() => {
                        setSelectedPlan(plan.id);
                        setSubsAmount(plan.unit_amount);
                      }}
                      className={`p-2  ${
                        subsAmount === plan.unit_amount
                          ? "bg-[#bcf0f2] border-[#38b6ff]"
                          : ""
                      }  border rounded`}
                    >
                      {plan.unit_amount / 100} €
                    </button>
                  ))}
                </div>
              ) : (
                <div className=" grid grid-cols-2 gap-2 ">
                  <button
                    onClick={() => {
                      setAmout(220);
                      setProductId("P-8AC067267N821094FM5NRDTI");
                    }}
                    className={`p-2  ${
                      amount === 220 ? "bg-[#bcf0f2] border-[#38b6ff]" : ""
                    }  border rounded`}
                  >
                    220 €
                  </button>
                  <button
                    onClick={() => {
                      setAmout(180);
                      setProductId("P-55C83945YS660005RM5M2XRI");
                    }}
                    className={`p-2  ${
                      amount === 180 ? "bg-[#bcf0f2] border-[#38b6ff]" : ""
                    }  border rounded`}
                  >
                    180 €
                  </button>
                  <button
                    onClick={() => {
                      setAmout(120);
                      setProductId("P-6V086680HK589745GM5M2NHI");
                    }}
                    className={`p-2  ${
                      amount === 120 ? "bg-[#bcf0f2] border-[#38b6ff]" : ""
                    }  border rounded`}
                  >
                    120 €
                  </button>
                  <button
                    onClick={() => {
                      setAmout(50);
                      setProductId("P-1VS037580U0373712M5MW2UQ");
                    }}
                    className={`p-2  ${
                      amount === 50 ? "bg-[#bcf0f2] border-[#38b6ff]" : ""
                    }  border rounded`}
                  >
                    50 €
                  </button>
                </div>
              )}

              {isMonth && paiment === "paypal" ? null : (
                <div className="flex flex-1 w-full mt-2">
                  <input
                    value={payAmount}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Si la valeur est vide, définir payAmount comme null
                      if (value === "") {
                        setPayAmount(null);
                      } else if (/^\d*$/.test(value)) {
                        setPayAmount(value);
                      }
                    }}
                    type="text"
                    placeholder="Montant libre"
                    className="p-2 w-full border rounded"
                  />
                </div>
              )}

              <p className="text-sm text-gray-500 mt-4">
                Chaque don contribue à un avenir meilleur pour tous.
              </p>
            </div>
          </div>

          {/* Section 2 : Mes coordonnées */}
          <div className="bg-white   rounded-lg shadow-lg">
            <div className=" bg-[#00075e]  py-[0.25rem] flex  justify-center items-center">
              <h2 className="text-[1rem]  md:text-xl font-bold  text-white text-center">
                2. Mes coordonnées
              </h2>
            </div>
            <div className="p-8">
              <DonationForm
                email={email}
                setEmail={setEmail}
                prenom={prenom}
                setPrenom={setPrenom}
                nom={nom}
                setNom={setNom}
                adresse={adresse}
                setAdresse={setAdresse}
                codePostal={codePostal}
                setCodePostal={setCodePostal}
                pays={pays}
                setPays={setPays}
                telephone={telephone}
                setTelephone={setTelephone}
                errors={errors}
              />

              <span className=" text-red-500">{champError} </span>
            </div>
          </div>

          {/* Section 3 : Mon règlement */}
          <div className="bg-white  rounded-lg shadow-lg">
            <div className=" bg-[#00075e]  py-[0.25rem] flex  justify-center items-center">
              <h2 className="text-[1rem]  md:text-xl font-bold  text-white text-center">
                3. Mon règlement
              </h2>
            </div>
            <div className=" p-8">
              <div className=" flex gap-2">
                <button
                  onClick={() => setPaiment("credit-carte")}
                  className={` ${
                    paiment === "credit-carte" ? "border-[#38b6ff]" : ""
                  }  p-2 flex flex-col items-center relative  uppercase text-[0.7rem] border rounded`}
                >
                  <span>
                    <img src={credit} alt="carte" className=" h-10 " />
                  </span>
                  {paiment === "credit-carte" && (
                    <span className="   -top-[10px] right-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-5 text-[#38b6ff]"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                  Carte Bancaire
                </button>
                <button
                  onClick={() => setPaiment("cheque")}
                  className={` ${
                    paiment === "cheque" ? "border-[#38b6ff]" : ""
                  }  p-2 flex flex-col items-center relative  uppercase text-[0.7rem] border rounded`}
                >
                  <span>
                    <img src={cheque} alt="cheque" className=" h-10 " />
                  </span>
                  {paiment === "cheque" && (
                    <span className="   -top-[10px] right-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-5 text-[#38b6ff]"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                  Chèque
                </button>
                <button
                  onClick={() => setPaiment("paypal")}
                  className={` ${
                    paiment === "paypal" ? "border-[#38b6ff]" : ""
                  }  p-2 flex flex-col items-center relative  uppercase text-[0.7rem] border rounded`}
                >
                  <span>
                    <img src={paypal} alt="paypal" className=" h-10 " />
                  </span>
                  {paiment === "paypal" && (
                    <span className="   -top-[10px] right-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-5 text-[#38b6ff]"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                  PayPal
                </button>
              </div>
              {loading ? (
                <div className=" mt-6 flex items-center justify-center">
                  <Spinner color={"#ed1212"} />
                </div>
              ) : (
                <button
                  onClick={handleCheckout}
                  className="w-full bg-red-600  text-white p-2 rounded mt-6"
                >
                  VALIDER MON DON
                </button>
              )}

              <p className="text-sm text-gray-500 mt-4">
                Paiements hautement sécurisés grâce à SSL.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationOption;
