import user1 from "../../public/event-user/user-0.png";
import user2 from "../../public/event-user/user-1.png";
import user3 from "../../public/event-user/user-2.png";
import user4 from "../../public/event-user/user-3.png";
import user5 from "../../public/event-user/user-4.png";
import user6 from "../../public/event-user/user-5.png";
import user7 from "../../public/event-user/user-7.png";
import user8 from "../../public/event-user/user-9.jpeg";
import user9 from "../../public/event-user/user-10.jpeg";
import user10 from "../../public/event-user/user-11.jpeg";
import user11 from "../../public/event-user/user-12.png";
import { Quote } from "lucide-react";

const speakers = [
  {
    name: "Dr. Fhemi TLAIES",
    role: "Médecin à la fondation évangélique Tannenhof Remscheid Allemagne",
    expertise: "Expert CEISNA",
    image: user2,
  },
  {
    name: "M. Idriss Nasser Adam",
    role: "Coordinateur du programme national de la santé numérique – Tchad",
    expertise: "",
    image: user6,
  },
  {
    name: "Dr. Didier LALAYE",
    role: "Médecin et CEO Dawa Mobile Health",
    expertise: "Expert CEISNA - Togo",
    image: user3,
  },
];

const speakers2 = [
  {
    name: "Dr. Manaaf OURO- AKPO",
    role: "Médecin en spécialité de santé publique,spécialiste Informatique médicale ",
    expertise: "Expert CEISNA - France",
    image: user8,
  },
  {
    name: "M. Koum Nkongo Aloys Dieudonné",
    role: "CEO Pharma Dream",
    expertise: "Expert CEISNA- Cameroun",
    image: user11,
  },
  {
    name: "M. Patrick MOELLE",
    role: "CEO MoulemaCare - Cameroun",
    expertise: "",
    image: user5,
  },
];

const SpeakersSection = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-6xl mx-auto px-6">
        <div className="text-center mt-6">
          <blockquote className="text-xl space-x-2 flex font-medium text-gray-600">
            <span>
              Des experts engagés pour le développement de la santé numérique en
              Afrique
            </span>

            <span className="text-4xl font-bold text-indigo-900">
              <Quote className=" h-6" />
            </span>
          </blockquote>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
          {speakers.map((speaker, index) => (
            <div
              key={index}
              className="bg-indigo-900 shadow-lg rounded-lg overflow-hidden text-center"
            >
              <div className="relative">
                <img
                  src={speaker.image}
                  alt={speaker.name}
                  className="w-full  h-[23rem] object-top object-cover"
                />
              </div>
              <div className=" mb-0 p-4 text-white">
                <h3 className="font-bold text-lg">{speaker.name}</h3>
                <p className="text-sm">{speaker.role}</p>
                {speaker.expertise && (
                  <p className="text-xs mt-1">{speaker.expertise}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-6 mt-20">
        <div className="text-end mt-6">
          <blockquote className="text-xl space-x-2 flex font-medium text-gray-600">
            <span>
              Des spécialistes dédiés à l’essor de la santé numérique en
              Afrique, œuvrant pour des solutions innovantes et accessibles au
              service des populations.
            </span>

            <span className="text-4xl font-bold text-indigo-900">
              <Quote className=" h-6" />
            </span>
          </blockquote>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
          {speakers2.map((speaker, index) => (
            <div
              key={index}
              className="bg-indigo-900 shadow-lg rounded-lg overflow-hidden text-center"
            >
              <div className="relative">
                <img
                  src={speaker.image}
                  alt={speaker.name}
                  className="w-full  h-[23rem] object-top object-cover"
                />
              </div>
              <div className=" mb-0 p-4 text-white">
                <h3 className="font-bold text-lg">{speaker.name}</h3>
                <p className="text-sm">{speaker.role}</p>
                {speaker.expertise && (
                  <p className="text-xs mt-1">{speaker.expertise}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpeakersSection;
