import { motion } from "framer-motion";

const LocationSelection = ({ onSelect }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <div className="mb-3">
        <h1 className="text-white text-[2rem]">1. Parlez-nous de vous</h1>
        <p className="text-gray-300">
          Veuillez sélectionner une option pour continuer.
        </p>
      </div>
      <div className="grid md:grid-cols-2 gap-8">
        <div
          onClick={() => onSelect("Afrique")}
          className="bg-blue-800/30 rounded-2xl p-8 text-white cursor-pointer hover:bg-blue-700/40 transition-colors"
        >
          <h3 className="text-2xl font-bold mb-2">Personne en Afrique</h3>
          <p className="text-blue-200">Pour les experts basés en Afrique</p>
        </div>
        <div
          onClick={() => onSelect("International")}
          className="bg-blue-800/30 rounded-2xl p-8 text-white cursor-pointer hover:bg-blue-700/40 transition-colors"
        >
          <h3 className="text-2xl font-bold mb-2">Personne Hors Afrique</h3>
          <p className="text-blue-200">Pour les experts internationaux</p>
        </div>
      </div>
    </motion.div>
  );
};

export default LocationSelection;
