import { motion } from "framer-motion";
import { Check, PartyPopper } from "lucide-react";

const ThankYouPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="max-w-2xl mx-auto text-center"
    >
      <div className="bg-white rounded-2xl p-12 shadow-xl">
        <div className="mb-8 flex justify-center">
          <div className="bg-green-100 p-4 rounded-full">
            <PartyPopper className="w-16 h-16 text-green-500" />
          </div>
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Merci de votre inscription !
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          Votre paiement a été traité avec succès. Bienvenue dans la communauté
          CEISNA Excellence !
        </p>
        <div className="space-y-4 text-left bg-blue-50 p-6 rounded-xl mb-8">
          <h3 className="font-semibold text-blue-900">Prochaines étapes :</h3>
          <ul className="space-y-3">
            <li className="flex items-start">
              <Check className="w-5 h-5 text-green-500 mr-2 mt-1" />
              <span className="text-blue-800">
                Vous recevrez un email de confirmation dans quelques minutes
              </span>
            </li>
            <li className="flex items-start">
              <Check className="w-5 h-5 text-green-500 mr-2 mt-1" />
              <span className="text-blue-800">
                Notre équipe vous contactera sous 24h pour finaliser votre
                intégration
              </span>
            </li>
            <li className="flex items-start">
              <Check className="w-5 h-5 text-green-500 mr-2 mt-1" />
              <span className="text-blue-800">
                Vous aurez accès à votre espace membre dès la validation de
                votre compte
              </span>
            </li>
          </ul>
        </div>
        <a
          href="/"
          className="inline-block bg-blue-600 text-white py-3 px-8 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
        >
          Retour à l'accueil
        </a>
      </div>
    </motion.div>
  );
};

export default ThankYouPage;
