import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Heart, Download, Share2, Info } from "lucide-react";

const RevueCard = ({ pub }) => {
  const [liked, setLiked] = useState(false);

  // Vérifier si le document est liké au chargement
  useEffect(() => {
    const storedLikes =
      JSON.parse(localStorage.getItem("likedDocuments")) || [];
    setLiked(storedLikes.includes(pub.id));
  }, [pub.id]);

  // Ajouter ou supprimer des favoris
  const toggleLike = () => {
    const storedLikes =
      JSON.parse(localStorage.getItem("likedDocuments")) || [];
    let updatedLikes;

    if (liked) {
      updatedLikes = storedLikes.filter((id) => id !== pub.id);
    } else {
      updatedLikes = [...storedLikes, pub.id];
    }

    localStorage.setItem("likedDocuments", JSON.stringify(updatedLikes));
    setLiked(!liked);
  };

  const printDocument = () => {
    const pdfUrl = pub.pdfUrl; // Assure-toi que l'URL est valide

    if (!pdfUrl) {
      alert("Aucun document disponible.");
      return;
    }

    window.open(pdfUrl, "_blank");
  };

  // Fonction de partage (Web Share API)
  const shareDocument = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: "Regarde ce document intéressant !",
          url: document.pdfUrl, // Assurez-vous que c'est une URL accessible
        });
      } catch (error) {
        console.error("Erreur de partage :", error);
      }
    } else {
      alert("Le partage Web n'est pas supporté sur ce navigateur.");
    }
  };
  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <Link to={`/revues/${pub.id}`} className="flex flex-col md:flex-row">
        {/* Checkbox et image */}
        <div className="flex p-4">
          <div className="w-40 h-56 flex-shrink-0 bg-gray-100 overflow-hidden">
            <img
              src={pub?.image}
              alt={pub?.title}
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        {/* Détails de la publication */}
        <div className="flex-1 p-4">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            {pub?.title}
          </h3>

          <div className="space-y-1 text-sm mb-3">
            <p className="text-gray-700">
              <span className="font-medium">Auteur institutionnel : </span>
              <a href="#" className="text-blue-600 hover:underline">
                {pub?.author}
              </a>{" "}
              {pub.authorCode}
            </p>

            {pub.personAsAuthor && (
              <p className="text-gray-700">
                <span className="font-medium">Auteur individuel : </span>
                <span>{pub?.personAsAuthor}</span>
              </p>
            )}

            {/* {pub?.doi && pub?.doi !== "" && (
              <p className="text-gray-700">
                <span className="font-medium">DOI : </span>
                <span>{pub?.doi}</span>
              </p>
            )} */}

            {pub.isbn && (
              <p className="text-gray-700">
                <span className="font-medium">ISSN : </span>
                <span>{pub.isbn}</span>
              </p>
            )}

            {pub.documentCode && (
              <p className="text-gray-700">
                <span className="font-medium">Code du document : </span>
                <span>{pub.documentCode}</span>
              </p>
            )}

            <p className="text-gray-700">
              <span className="font-medium">Pages : </span>
              <span>
                {pub?.pages} pages
                {pub.hasIllustrations ? " : illustrations" : ""}
              </span>
            </p>

            <p className="text-gray-700">
              <span className="font-medium">Langue : </span>
              <span>{pub?.language}</span>
            </p>

            <p className="text-gray-700">
              <span className="font-medium">Année de publication : </span>
              <span>{pub?.year}</span>
            </p>

            <p className="text-gray-700">
              <span className="font-medium">Type de licence : </span>
              <a
                href="#"
                className="text-blue-600 hover:underline  items-center inline-flex"
              >
                <Info size={14} className="mr-1" />
                {pub?.licenceType}
              </a>
            </p>

            <p className="text-gray-700">
              <span className="font-medium">Type de document : </span>
              <span>{pub?.documentType}</span>
            </p>
          </div>
        </div>
      </Link>

      <div className="bg-gray-50 md:px-4 py-3  flex flex-col md:flex-row justify-between border-t">
        <div className="flex mb-2  items-center">
          <span className="bg-blue-600 text-white px-4 py-2 rounded-md font-medium">
            En ligne
          </span>
          <span className="bg-orange-500 text-white px-4 py-2 rounded-md font-medium ml-2">
            Accès libre
          </span>
        </div>

        <div className="flex items-center space-x-6">
          {/* Bouton Favoris */}
          <button
            onClick={toggleLike}
            className={`flex flex-col items-center text-xs ${
              liked ? "text-red-500" : "text-gray-600"
            }`}
          >
            <Heart size={20} className="mb-1" />
            Favoris
          </button>

          {/* Bouton Imprimer */}
          <button
            onClick={printDocument}
            className="flex flex-col items-center text-gray-600 text-xs"
          >
            <Download size={20} className="mb-1" />
            Télécharger
          </button>

          {/* Bouton Partager */}
          <button
            onClick={shareDocument}
            className="flex flex-col items-center text-gray-600 text-xs"
          >
            <Share2 size={20} className="mb-1" />
            Partager
          </button>
        </div>
      </div>
    </div>
  );
};

export default RevueCard;
