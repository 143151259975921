const ConferenceTitle = ({ title, description }) => {
  return (
    <div className="bg-indigo-900 text-white py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl lg:text-5xl font-bold mb-4">{title}</h1>
        <p className="text-xl text-indigo-200">{description}</p>
      </div>
    </div>
  );
};

export default ConferenceTitle;
