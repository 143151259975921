import React, { useState } from "react";
import {
  Book,
  FileText,
  Filter,
  ChevronDown,
  Heart,
  Plus,
  Printer,
  Share2,
  Check,
  Info,
} from "lucide-react";
import { useCollection } from "../hooks/useCollection";
import { Link } from "react-router-dom";
import Spinner from "../componants/utilities/Spinner";
import RevueCard from "../componants/revues/RevueCard";

function Revues() {
  const { documents, loading, error } = useCollection("publications");
  // État pour les filtres
  const [openFilter, setOpenFilter] = useState([0, 1]);
  const [sortByDate, setSortByDate] = useState("Croissant");

  const [docType, setDocType] = useState([]);
  const [docLanguage, setDocLanguage] = useState([]);

  const typeFilter = {
    title: "Type de matériel",
    options: [
      { name: "Article", count: 4021 },
      { name: "Livre", count: 3774 },
      { name: "Magazine", count: 3715 },
      { name: "Document", count: 389 },
    ],
  };

  const langueFilter = {
    title: "Langue",

    options: [
      { name: "Anglais", count: 5111 },
      { name: "Français", count: 2351 },
      { name: "Espagnol", count: 2247 },
      { name: "Arabe", count: 1164 },
      { name: "Portugais", count: 967 },
    ],
  };

  // const [filters, setFilters] = useState([
  //   {
  //     title: "Type de matériel",
  //     expanded: true,
  //     options: [
  //       { name: "article", count: 4021 },
  //       { name: "Livre", count: 3774 },
  //       { name: "Magazine", count: 3715 },
  //       { name: "Document de réunion", count: 389 },
  //     ],
  //   },
  //   {
  //     title: "Nature du contenu",
  //     expanded: true,
  //     options: [
  //       { name: "Livre", count: 1233 },
  //       { name: "Magazine", count: 716 },
  //       { name: "Article", count: 237 },
  //       { name: "Document de réunion", count: 109 },
  //     ],
  //   },
  //   {
  //     title: "Source",
  //     expanded: true,
  //     options: [
  //       { name: "UNESCO", count: 14519 },
  //       { name: "Soutenu par l'UNESCO", count: 67 },
  //       { name: "Non-UNESCO", count: 4 },
  //     ],
  //   },
  //   {
  //     title: "Langue",
  //     expanded: true,
  //     options: [
  //       { name: "Anglais", count: 5111 },
  //       { name: "Français", count: 2351 },
  //       { name: "Espagnol", count: 2247 },
  //       { name: "Arabe", count: 1164 },
  //       { name: "Portugais", count: 967 },
  //     ],
  //   },
  //   {
  //     title: "Thème",
  //     expanded: true,
  //     options: [
  //       { name: "Gestion des ressources en eau", count: 1017 },
  //       { name: "Histoire de l'Afrique", count: 933 },
  //       { name: "Politique éducative", count: 844 },
  //       { name: "Changement climatique", count: 606 },
  //     ],
  //   },
  // ]);

  // Fonction pour basculer l'état d'expansion d'une catégorie de filtre
  // const toggleFilterExpansion = (index) => {
  //   const newFilters = [...filters];
  //   newFilters[index].expanded = !newFilters[index].expanded;
  //   setFilters(newFilters);
  // };
  let filterPublication = [...documents];

  if (docType.length > 0) {
    filterPublication = filterPublication.filter((doc) =>
      docType.includes(doc.documentType)
    );
  }

  if (docLanguage.length > 0) {
    filterPublication = filterPublication.filter((doc) =>
      docLanguage.includes(doc.language)
    );
  }

  if (sortByDate === "Croissant") {
    filterPublication.sort((a, b) => new Date(a.year) - new Date(b.year));
  }

  if (sortByDate === "Decroissant") {
    filterPublication.sort((a, b) => new Date(b.year) - new Date(a.year));
  }

  console.log(filterPublication);

  const intialisateFilter = () => {
    setDocType([]);
    setDocLanguage([]);
  };

  if (loading) {
    return (
      <div className=" items-center flex justify-center h-[70vh] w-[95%] md:w-[70%] lg:w-[50%] mx-auto">
        <Spinner loading={loading} />
      </div>
    );
  }
  return (
    <main>
      {/* En-tête */}
      <header className="bg-white mt-14 md:mt-20  shadow-sm py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold text-gray-800">
            Publications RAINAM
          </h1>
        </div>
      </header>

      {/* Contenu principal */}
      <section className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Barre latérale des filtres */}
          <aside className="w-full md:w-1/4 bg-white rounded-lg shadow-sm p-4">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold text-gray-800">Filtres</h2>
              <button
                onClick={intialisateFilter}
                className="flex items-center text-blue-600 text-sm"
              >
                <Filter size={16} className="mr-1" />
                Effacer tout
              </button>
            </div>

            {/* Liste des filtres */}
            <div className="space-y-4 border-r-[1px] border-r-gray-100 pr-2">
              <div className="border-b pb-4 last:border-b-0">
                <button
                  className="flex items-center justify-between w-full text-left font-medium mb-2"
                  onClick={() =>
                    setOpenFilter((prev) =>
                      prev.includes(1)
                        ? prev.filter((item) => item !== 1)
                        : [...prev, 1]
                    )
                  }
                >
                  {typeFilter.title}
                  <ChevronDown
                    size={18}
                    className={`transition-transform ${
                      openFilter.includes(1) ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {openFilter.includes(1) && (
                  <ul className="space-y-2">
                    {typeFilter.options.map((option, optIndex) => (
                      <li
                        key={optIndex}
                        className="flex items-center justify-between text-sm"
                      >
                        <div className="flex items-center">
                          <div
                            onClick={() =>
                              setDocType((prev) =>
                                prev.includes(option.name)
                                  ? prev.filter((item) => item !== option.name)
                                  : [...prev, option.name]
                              )
                            }
                            className={` ${
                              docType.includes(option.name)
                                ? "bg-[#00075e]"
                                : ""
                            }  w-5 h-5  cursor-pointer  border-[1px] border-gray-300 rounded-full flex items-center justify-center mr-2`}
                          >
                            {docType.includes(option.name) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4 text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m4.5 12.75 6 6 9-13.5"
                                />
                              </svg>
                            )}
                          </div>
                          <span>{option.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="border-b pb-4 last:border-b-0">
                <button
                  className="flex items-center justify-between w-full text-left font-medium mb-2"
                  onClick={() =>
                    setOpenFilter((prev) =>
                      prev.includes(0)
                        ? prev.filter((item) => item !== 0)
                        : [...prev, 0]
                    )
                  }
                >
                  {langueFilter.title}
                  <ChevronDown
                    size={18}
                    className={`transition-transform ${
                      openFilter.includes(0) ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {openFilter.includes(0) && (
                  <ul className="space-y-2">
                    {langueFilter.options.map((option, optIndex) => (
                      <li
                        key={optIndex}
                        className="flex items-center justify-between text-sm"
                      >
                        <div className="flex items-center">
                          <div
                            onClick={() =>
                              setDocLanguage((prev) =>
                                prev.includes(option.name)
                                  ? prev.filter((item) => item !== option.name)
                                  : [...prev, option.name]
                              )
                            }
                            className={` ${
                              docLanguage.includes(option.name)
                                ? "bg-[#00075e]"
                                : ""
                            }  w-5 h-5  cursor-pointer  border-[1px] border-gray-300 rounded-full flex items-center justify-center mr-2`}
                          >
                            {docLanguage.includes(option.name) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4 text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m4.5 12.75 6 6 9-13.5"
                                />
                              </svg>
                            )}
                          </div>
                          <span>{option.name}</span>
                        </div>
                        {/* <span className="text-gray-500">({option.count})</span> */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </aside>

          {/* Liste des publications */}
          <div className="w-full md:w-3/4">
            <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">
                  Résultats de recherche
                </h2>
                <div className="flex items-center text-sm text-gray-600">
                  <span>Trier par : </span>
                  <button
                    onClick={() =>
                      setSortByDate((sort) =>
                        sort === "Croissant" ? "Décroissant" : "Croissant"
                      )
                    }
                    className="ml-2 flex items-center font-medium"
                  >
                    {sortByDate} <ChevronDown size={16} className="ml-1" />
                  </button>
                </div>
              </div>
            </div>

            {/* Publications */}
            <div className="space-y-6">
              {filterPublication.map((pub) => (
                <RevueCard key={pub.id} pub={pub} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Revues;
