import { Calendar, MapPin } from "lucide-react";

const HeroSection = () => {
  return (
    <div className="  flex items-center justify-center ">
      <div className="relative w-full   overflow-hidden ">
        <div className="relative h-[500px] lg:h-[600px] ">
          <img
            src="https://images.unsplash.com/photo-1551601651-2a8555f1a136?q=80&w=3158&auto=format&fit=crop"
            alt="Audience dans une salle de conférence"
            className="absolute inset-0 w-full h-full object-cover brightness-50"
          />

          {/* Contenu de la bannière */}
          <div className="relative z-10 py-10 md:pt-40 xl:py-20 h-full flex flex-col justify-between px-8">
            <div className="space-y-4">
              <h1 className="text-white text-3xl lg:text-5xl font-bold tracking-tight">
                SANTÉ ET INNOVATION
                <br />
                EN AFRIQUE
              </h1>

              <p className="text-white text-xl max-w-2xl leading-relaxed">
                Intelligence artificielle en santé, synergies internationales
                pour des solutions innovantes, centrées sur l'Afrique et le
                monde
              </p>
            </div>

            <div className="space-y-4">
              <div className="inline-flex items-center gap-4 bg-white bg-opacity-10 backdrop-blur-sm rounded-lg p-4">
                <div className="flex items-center gap-2">
                  <Calendar className="text-white" size={24} />
                  <span className="text-white text-xl">05 Avril</span>
                </div>
                <div className="w-px h-6 bg-white/30" />
                <div className="flex items-center gap-2">
                  <MapPin className="text-white" size={24} />
                  <span className="text-white text-xl">PARIS</span>
                </div>
              </div>

              <h2 className="text-white text-4xl lg:text-6xl font-bold">
                Conférence
              </h2>

              <div className="absolute bottom-0 right-0 w-20 lg:w-72 h-4 lg:h-24 bg-white transform translate-x-24 translate-y-12 rotate-45" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
