import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import EvenScheduleHeader from "./EvenScheduleHeader";
import partenaire1 from "../../public/partenaire/partenaire-1.jpeg";
import partenaire2 from "../../public/partenaire/partenaire-2.svg";
import partenaire3 from "../../public/partenaire/partenaire-3.png";
import partenaire4 from "../../public/partenaire/partenaire-4.jpeg";
import partenaire5 from "../../public/partenaire/partenaire-5.jpeg";
import partenaire6 from "../../public/partenaire/partenaire-6.jpg";

// ✅ Composant pour chaque élément de la timeline
const EventItem = ({ event }) => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: -50 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.5 }}
      className="mb-6 ml-6 relative"
    >
      <div className="absolute -left-[2.3rem] w-6 h-6 bg-[#003f97] rounded-full border-4 border-white"></div>
      <div className="bg-[#003f97] text-white py-2 px-4 rounded-lg inline-block font-semibold">
        {event.time}
      </div>
      <h3 className="text-lg font-bold mt-2">{event.title}</h3>
      {event.description && (
        <p className="text-gray-700 text-sm mt-1 whitespace-pre-line">
          {event.description}
        </p>
      )}
    </motion.div>
  );
};

const EventSchedule = ({ date, sect, partImg, events }) => {
  return (
    <div>
      <EvenScheduleHeader date={date} sect={sect} />
      <section className=" lg:container grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="p-8">
          <img src={partImg} alt="Partenariat" className="rounded-lg " />
          <div className="mt-6">
            <h3 className="text-lg font-semibold">En partenariat avec</h3>
            <div className="flex flex-wrap items-center gap-10 mt-4">
              <img src={partenaire5} alt="Logo 1" className=" h-16 lg:h-24" />
              <img src={partenaire4} alt="Logo 1" className=" h-16 lg:h-24" />
              <img src={partenaire3} alt="Logo 1" className="h-10 lg:h-16" />
              <img src={partenaire1} alt="Logo 1" className="h-16 lg:h-20" />
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold">Et participation de</h3>
            <div className="flex flex-wrap items-center gap-6 mt-4">
              <img src={partenaire2} alt="Logo 1" className="h-16 lg:h-20" />
              <img src={partenaire6} alt="Logo 1" className="h-16 lg:h-20" />
            </div>
          </div>
        </div>

        <div>
          <div className=" lg:hidden p-8 bg-gray-100 ">
            <div className="space-y-4 ">
              <h2 className="text-4xl lg:text-6xl font-bold text-navy-900">
                Programme de la conférence
              </h2>
              <div className="space-y-2">
                <h3 className="text-2xl font-semibold text-indigo-600">
                  {sect}
                </h3>
                <p className="text-gray-700 font-medium">{date}</p>
              </div>
            </div>
          </div>
          <div className="max-w-4xl mx-auto p-6">
            <div className="relative border-dashed border-l-2 border-[#00075e]">
              {events.map((event, index) => (
                <EventItem key={index} event={event} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventSchedule;
