import React from "react";
import PageHeader from "../componants/utilities/PageHeader";
import JoindUs from "../componants/Home/JoindUs";
import Actualities from "../componants/Home/Actualities";
import banner from "../public/equipe/banner.jpeg";
import Respo from "../componants/Teams/Respo";
import AboutHero from "../componants/aboutUs/AboutHero";
import OurTeam from "../componants/aboutUs/OurTeam";

const NotreEquipe = () => {
  const pageHeader = {
    title: "Notre Equipe",
    description:
      "Notre équipe est composée de professionnels passionnés, dédiés à fournir des solutions innovantes et efficaces.",
    banner: banner,
  };

  const heroText = `Le Comité d’Experts Internationaux en Santé Numérique pour l’Afrique (CEISNA) a eu un impact profond et transformationnel sur le secteur de la santé en Afrique. En intégrant des technologies de pointe telles que l'intelligence artificielle le le big data,  le CEISNA a permet une modernisation rapide des infrastructures sanitaires du continent.`;

  return (
    <main>
      <PageHeader w={"80%"} pageHeader={pageHeader} />
      <AboutHero heroText={heroText} />
      <Respo />
      {/* <OurTeam /> */}
      <JoindUs />
      <Actualities />
    </main>
  );
};

export default NotreEquipe;
