import React, { useState } from "react";
import {
  CreditCard,
  Check,
  Shield,
  Users,
  Trophy,
  Target,
  Building2,
  User,
} from "lucide-react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { motion, AnimatePresence } from "framer-motion";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase/config";
import { africPlans, companyPlans, plansHorsAfrique } from "../data/plans";
import PlanCard from "../componants/membership/PlanCard";
import LocationSelection from "../componants/membership/LocationSelection";
import ExpertTypeSelection from "../componants/membership/ExpertTypeSelection";
import ProgressBar from "../componants/membership/ProgressBar";
import CheckoutForm from "../componants/membership/CheckoutForm";
import ThankYouPage from "../componants/membership/ThankYouPage";

const stripePromise = loadStripe(
  "pk_live_51QUTZ0Bs7eYDY6jWNl1tVuJQm61qBRaAQJJUsFsFAoKzcZKJFdcMmy4wkPXTX7noRU40IeiptZ9315Bng0GzH4ib00waXAnybJ"
);

function Membership() {
  const [step, setStep] = useState(1);
  const [expertType, setExpertType] = useState(null);
  const [location, setLocation] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("professionnel");

  const handleExpertTypeSelect = (type) => {
    setExpertType(type);
    setStep(2);
  };

  const handleLocationSelect = (loc) => {
    setLocation(loc);
    setStep(3);
  };

  return (
    <div className=" py-10 bg-gradient-to-b from-blue-900 to-blue-950">
      <div className="max-w-7xl mx-auto  space-y-20 px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">
            Rejoignez CEISNA Excellence
          </h1>
          <p className="text-xl text-blue-200">
            Investissez dans l'excellence et l'impact en santé publique
          </p>
        </div>
        {step !== 4 && <ProgressBar step={step} />}

        <AnimatePresence mode="wait">
          {step === 1 && (
            <ExpertTypeSelection onSelect={handleExpertTypeSelect} />
          )}
          {step === 2 && <LocationSelection onSelect={handleLocationSelect} />}

          {step === 3 &&
            location === "International" &&
            expertType === "Personne physique" && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="grid md:grid-cols-3 gap-8"
                >
                  {Object.entries(plansHorsAfrique).map(([key, plan]) => (
                    <PlanCard
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      key={key}
                      planKey={key}
                      plan={plan}
                    />
                  ))}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="max-w-2xl mx-auto mt-16 bg-white rounded-2xl p-8 shadow-xl"
                >
                  <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Information de Paiement
                  </h2>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      typeExpert={expertType}
                      localisation={location}
                      amount={plansHorsAfrique[selectedPlan].price}
                      priceId={plansHorsAfrique[selectedPlan].priceId}
                    />
                  </Elements>

                  <div className="mt-6 flex items-center justify-center space-x-4 text-sm text-gray-600">
                    <Shield className="w-5 h-5 text-green-500" />
                    <span>Paiement sécurisé 256-bit SSL</span>
                  </div>
                </motion.div>
              </>
            )}

          {step === 3 &&
            location === "International" &&
            expertType === "Personne moral" && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="grid md:grid-cols-3 gap-8"
                >
                  {Object.entries(companyPlans).map(([key, plan]) => (
                    <PlanCard
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      key={key}
                      planKey={key}
                      plan={plan}
                    />
                  ))}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="max-w-2xl mx-auto mt-16 bg-white rounded-2xl p-8 shadow-xl"
                >
                  <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Information de Paiement
                  </h2>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      typeExpert={expertType}
                      localisation={location}
                      amount={companyPlans[selectedPlan].price}
                      priceId={plansHorsAfrique[selectedPlan].priceId}
                    />
                  </Elements>

                  <div className="mt-6 flex items-center justify-center space-x-4 text-sm text-gray-600">
                    <Shield className="w-5 h-5 text-green-500" />
                    <span>Paiement sécurisé 256-bit SSL</span>
                  </div>
                </motion.div>
              </>
            )}

          {step === 3 &&
            location === "Afrique" &&
            expertType === "Personne moral" && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="grid md:grid-cols-3 gap-8"
                >
                  {Object.entries(companyPlans).map(([key, plan]) => (
                    <PlanCard
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      key={key}
                      planKey={key}
                      plan={plan}
                    />
                  ))}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="max-w-2xl mx-auto mt-16 bg-white rounded-2xl p-8 shadow-xl"
                >
                  <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Information de Paiement
                  </h2>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      typeExpert={expertType}
                      localisation={location}
                      amount={companyPlans[selectedPlan].price}
                      priceId={plansHorsAfrique[selectedPlan].priceId}
                    />
                  </Elements>

                  <div className="mt-6 flex items-center justify-center space-x-4 text-sm text-gray-600">
                    <Shield className="w-5 h-5 text-green-500" />
                    <span>Paiement sécurisé 256-bit SSL</span>
                  </div>
                </motion.div>
              </>
            )}

          {step === 3 &&
            location === "Afrique" &&
            expertType === "Personne physique" && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="grid md:grid-cols-3 gap-8"
                >
                  {Object.entries(africPlans).map(([key, plan]) => (
                    <PlanCard
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      key={key}
                      planKey={key}
                      plan={plan}
                    />
                  ))}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="max-w-2xl mx-auto mt-16 bg-white rounded-2xl p-8 shadow-xl"
                >
                  <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Information de Paiement
                  </h2>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      typeExpert={expertType}
                      localisation={location}
                      amount={africPlans[selectedPlan].price}
                      priceId={plansHorsAfrique[selectedPlan].priceId}
                    />
                  </Elements>

                  <div className="mt-6 flex items-center justify-center space-x-4 text-sm text-gray-600">
                    <Shield className="w-5 h-5 text-green-500" />
                    <span>Paiement sécurisé 256-bit SSL</span>
                  </div>
                </motion.div>
              </>
            )}
          {step === 4 && <ThankYouPage />}
        </AnimatePresence>

        <div className="mt-16 grid md:grid-cols-3 gap-8 text-white">
          <div className="text-center">
            <div className="bg-blue-800/30 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Users className="w-8 h-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Communauté d'Experts</h3>
            <p className="text-blue-200">
              Rejoignez un réseau de professionnels de santé passionnés
            </p>
          </div>
          <div className="text-center">
            <div className="bg-blue-800/30 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Trophy className="w-8 h-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Excellence Garantie</h3>
            <p className="text-blue-200">
              Accédez à des ressources et formations de haute qualité
            </p>
          </div>
          <div className="text-center">
            <div className="bg-blue-800/30 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Target className="w-8 h-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Impact Mesurable</h3>
            <p className="text-blue-200">
              Contribuez à l'amélioration des soins de santé
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;
