import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import { app } from "../../firebase/config";

const CheckoutForm = ({ typeExpert, localisation, amount, priceId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");

  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setProcessing(true);
    setError(null);

    if (!stripe || !elements) return;

    // 🔹 Création du paiement
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
      setProcessing(false);
      return;
    }

    const functions = getFunctions(app);
    const createSession = httpsCallable(functions, "sendEventRegister");

    const result = await createSession({
      email,
      paymentMethodId: paymentMethod.id,
      priceId,
      typeExpert,
      localisation,
    });

    if (result.data.error) {
      console.error(result.data.error);
      setProcessing(false);
      return;
    }

    const confirm = await stripe.confirmCardPayment(result.data.clientSecret);

    if (confirm.error) {
      console.error(confirm.error);
      setError(confirm.error.message || "Une erreur est survenue.");
    } else {
      setError("Paiement réussi !");
    }

    setProcessing(false);
  };
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Prénom
          </label>
          <input
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            type="text"
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Nom
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email Professionnel
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Informations de Carte
        </label>
        <div className="p-4 border border-gray-300 rounded-lg">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
      </div>

      {error && <div className="text-red-600 text-sm">{error}</div>}

      <button
        type="submit"
        disabled={!stripe || processing}
        className={`w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-semibold transition-colors ${
          !stripe || processing
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-700"
        }`}
      >
        {processing ? "Traitement..." : `Payer ${amount}`}
      </button>
    </form>
  );
};

export default CheckoutForm;
