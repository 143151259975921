import ConferenceTitle from "../componants/conference/ConferenceTitle";
import EventSchedule from "../componants/conference/EventSchedule";
import { GuestOfHonor } from "../componants/conference/GuestOfHonor";
import HeroSection from "../componants/conference/HeroSection";
import Interviews from "../componants/conference/Interviews";
import SpeakersSection from "../componants/conference/SpeakersSection";
import image from "../public/images/ambassadeur.png";

const partImg =
  "https://images.unsplash.com/photo-1672380135241-c024f7fbfa13?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const partImg2 =
  "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=3538&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const events = [
  { time: "14H:00 - 14H:15", title: "Arrivée" },
  {
    time: "14H:15 - 14H:30",
    title: "Séance Inaugurale",
    description:
      "Lancement officiel de la conférence avec une introduction sur les objectifs et enjeux de l'événement.\nPhoto inaugurale.",
  },
  {
    time: "14H:30 - 14H55",
    title:
      "Table Ronde 1 : Synergies internationales pour l'innovation en santé en Afrique et dans le monde",
    description:
      "Intervenants :\n- Madame Mary Akangbe (UK)\n- M. Mamane Alassane Ahmed (Niger)\n- M. Idriss Nasser Adam (Tchad)\nModérateur : Dr Guy Moutembi",
  },
  {
    time: "15H:00 - 15H10",
    title: "Intervention Spéciale : Éthique et IA en Santé",
    description:
      "Intervenant : Dr. Jean Toube (Cameroun)\nModérateur : Dr Ouro-Akpo Manaaf",
  },
  {
    time: "15H:15 - 15H45",
    title: "Table Ronde 2 : IA et Santé en Afrique",
    description:
      "Intervenants :\n- M. Aloys Koum (Cameroun)\n- Dr Fehmi Tilais (Allemagne)\nModérateur : Patrick Mouelle",
  },
  { time: "15H:45 - 16H00", title: "Pause Café" },
];

const events2 = [
  {
    time: "16H:00 - 16H:25",
    title: "Table Ronde 3 : Stratégies pour renforcer les capacités en santé",
    description: [
      "Dr Aly Bocoum - Médecin généraliste au district sanitaire de Thiadiaye Sénégal",
      "Dr Ndoh Ngrabé Nodje-Assal, Ancien- Conseiller à la santé publique de la primature du Tchad",
      "Adrien Rabetarno - Responsable Programmes Amref Health Africa (France)",
      "Pr Cheick Oumar Bagayogo, Professeur agrégé en informatique médicale, Directeur du Centre d’innovation et de santé digitale (Digi-Santé-Mali)",
    ],
    moderator: "Dr Ouro-Akpo Manaaf",
  },
  {
    time: "16H:30 - 16H:55",
    title: "Table Ronde 4 : Dossier patient différentes approches",
    description: [
      "M. Aloys Koum, CEO Pharma Dream - Cameroun",
      "Dr Didier Lalaye, CEO DAWA Mobile Health - Tchad",
      "Mme Yacine SARR, CEO Plateforme WER - Sénégal",
    ],
    moderator: "Patrick Mouelle",
  },
  {
    time: "17H:00 - 17H:15",
    title:
      "Panel : Présentation des activités du CEISNA et d’AMREF Health Africa",
    description: [
      "Mme. Laure BEYALA, Présidente CEISNA",
      "M. Josselyn LEON, Délégué Général Amref Health Africa (France)",
    ],
    moderator: "Patrick Mouelle",
  },
  {
    time: "17H:20 - 17H:40",
    title: "Interviews Exclusives (10 min chacune)",
    themes: [
      "Le Rôle Stratégique du Numérique dans le Renforcement des Systèmes de Santé au Niger et en Afrique",
      "Santé Numérique et Partenariats Stratégiques : Construire un Écosystème Durable au Tchad et en Afrique",
    ],
    moderator: "Dr Jean Toube",
  },
  {
    time: "17H:45 - 18H:00",
    title: "Conclusions et clôture",
  },
];

const Conference = () => {
  return (
    <div>
      <HeroSection />
      <GuestOfHonor
        name="Monsieur Georges Jude Emmanuel Tirant"
        title="Ambassadeur des Seychelles en France"
        subtitle="Délégué Permanent des Seychelles auprès de l'Organisation des Nations Unies pour l'éducation, la science et la culture (UNESCO)"
        imageUrl={image}
        description="Nous avons l'immense honneur d'accueillir Son Excellence Monsieur Georges Jude Emmanuel Tirant, Ambassadeur des Seychelles en France, en tant qu'invité d'honneur de notre Conférence Internationale sur la Santé et l'Innovation, qui se tiendra le 5 avril 2025 à Paris. Sa présence témoigne de l'engagement des Seychelles en faveur de l'innovation et du développement des systèmes de santé en Afrique. Nous sommes ravis de bénéficier de son éclairage et de son expérience diplomatique pour enrichir nos échanges sur les enjeux et opportunités de la santé numérique sur le continent."
        signature="Présidente du CEISNA"
      />
      <EventSchedule
        sect="Session 1"
        date="Samedi | 05 Avril 2025"
        events={events}
        partImg={partImg}
      />
      <EventSchedule
        sect="Session 2"
        date="Samedi | 05 Avril 2025"
        events={events2}
        partImg={partImg2}
      />
      <ConferenceTitle
        title="Nos Speakers"
        description="Découvrez les experts qui animeront nos conférences et discussions."
      />
      <SpeakersSection />
      <ConferenceTitle
        title="Interviews Exclusives"
        description="Découvrez nos entretiens avec des experts du domaine"
      />

      <Interviews />
    </div>
  );
};

export default Conference;

// import React from 'react';
// import { Calendar, MapPin } from 'lucide-react';

// function App() {
//   return (
//     <div className="min-h-screen bg-gradient-to-br from-blue-900 to-blue-700 flex items-center justify-center p-4">
//       <div className="max-w-3xl w-full bg-white/10 backdrop-blur-lg rounded-2xl overflow-hidden shadow-2xl">
//         <div className="relative">
//           <img
//             src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&q=80&w=2000"
//             alt="Innovation médicale"
//             className="w-full h-64 object-cover"
//           />
//           <div className="absolute inset-0 bg-blue-900/40"></div>
//         </div>

//         <div className="p-8">
//           <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
//             SANTÉ ET INNOVATION EN AFRIQUE
//           </h1>

//           <p className="text-xl text-blue-100 mb-8">
//             Intelligence artificielle en santé, synergies internationales pour des solutions innovantes, centrées sur l'Afrique et le monde
//           </p>

//           <div className="flex flex-col md:flex-row gap-6 items-start md:items-center">
//             <div className="flex items-center gap-2 bg-white/20 rounded-full px-6 py-3">
//               <Calendar className="w-6 h-6 text-blue-200" />
//               <span className="text-white font-semibold">05 Avril 2025</span>
//             </div>

//             <div className="flex items-center gap-2 bg-white/20 rounded-full px-6 py-3">
//               <MapPin className="w-6 h-6 text-blue-200" />
//               <span className="text-white font-semibold">PARIS</span>
//             </div>
//           </div>

//           <button className="mt-8 bg-white text-blue-900 hover:bg-blue-50 transition-colors px-8 py-3 rounded-full font-bold text-lg">
//             S'inscrire à l'événement
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default App;
