const EvenScheduleHeader = ({ date, sect }) => {
  return (
    <div className=" bg-gray-100">
      <div className=" container mx-auto  px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className=" rounded-2xl  ">
            <div className="flex items-center gap-3 mb-6">
              <h2 className=" text-4xl lg:text-6xl font-bold text-navy-900">
                Nos Partenaires
              </h2>
            </div>
            <p className="text-gray-600 leading-relaxed">
              Ce forum représente une plateforme essentielle consacrée à
              l'évolution numérique des soins de santé en Afrique, rassemblant
              professionnels du secteur, autorités gouvernementales, innovateurs
              technologiques et représentants d'institutions pour dialoguer sur
              les défis et perspectives qu'apporte la révolution digitale dans
              l'écosystème sanitaire africain.
            </p>
          </div>

          <div className=" hidden lg:block ">
            <div className="space-y-4">
              <h2 className="text-4xl lg:text-6xl font-bold text-navy-900">
                Programme de la conférence
              </h2>
              <div className="space-y-2">
                <h3 className="text-2xl font-semibold text-indigo-600">
                  {sect}
                </h3>
                <p className="text-gray-700 font-medium">{date}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvenScheduleHeader;
