import NotreMission from "../pages/NotreMission";
import BecomeMember from "../pages/BecomeMember";
import Home from "../pages/Home";
import NosValeurs from "../pages/NosValeurs";
import NotreEquipe from "../pages/NotreEquipe";
import NotreImpact from "../pages/NotreImpact";
import NosProjets from "../pages/NosProjets";
import ContactezNous from "../pages/Contactez-Nous";
import Events from "../pages/Events";
import RapportEvents from "../pages/RapportEvents";
import DetailsRapports from "../pages/DetailsRapports";
import Eventsdetails from "../pages/Eventsdetails";
import Activities from "../pages/Activities";
import CommissionsScientifiques from "../pages/CommissionsScientifiques";
import CommissionsRecherche from "../pages/CommissionsRecherche";
import CommeissionsFomation from "../pages/CommeissionsFomation";
import GroupeConsultatif from "../pages/GroupeConsultatif";
import CooperationEntreprise from "../pages/CooperationEntreprise";
import Ressources from "../pages/Ressources";
import Expert from "../pages/Expert";
import AboutUs from "../pages/AboutUs";
import Actualites from "../pages/Actualites";
import NewDetails from "../pages/NewDetails";
import GroupeExpert from "../pages/GroupeExpert";
import Renuon from "../pages/Renuon";
import PublicationsRapports from "../pages/PublicationsRapports";
import BibliothequeRessources from "../pages/BibliothequeRessources";
import CommissionEthiqueEtDeontologie from "../pages/CommissionEthiqueEtDeontologie";
import OrganisationsPartenaire from "../pages/OrganisationsPartenaire";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Donation from "../pages/Donation";
import CheckOutPaypal from "../pages/CheckOutPaypal";
import CheckOutPaypalSubscription from "../pages/CheckOutPaypalSubscription";
import CheqSendSuccess from "../pages/CheqSendSuccess";
import StripeSuccess from "../pages/StripeSuccess";
import Revues from "../pages/Revues";
import RevueDetails from "../pages/RevueDetails";
import Conference from "../pages/Conference";
import Membership from "../pages/Membership";

export function usePages() {
  const publicUrl = [
    { path: "/", Component: Home, exact: true },
    { path: "/devenir-membre", Component: BecomeMember, exact: true },
    { path: "/revues", Component: Revues },
    { path: "/revues/:id", Component: RevueDetails },
    { path: "/notre-mission", Component: NotreMission },
    { path: "/don", Component: Donation },
    { path: "/don/paypal-chechout/:amount", Component: CheckOutPaypal },
    { path: "/adhesion", Component: Membership },
    {
      path: "/don/abonnement/paypal-chechout/:productId",
      Component: CheckOutPaypalSubscription,
    },

    {
      path: "/don/cheque-sended",
      Component: CheqSendSuccess,
    },

    {
      path: "/conference",
      Component: Conference,
    },

    {
      path: "/payement/success",
      Component: StripeSuccess,
    },

    { path: "/nos-valeurs", Component: NosValeurs },
    { path: "/notre-equipe", Component: NotreEquipe },
    { path: "/notre-impact", Component: NotreImpact },
    { path: "/nos-projets", Component: NosProjets },
    { path: "/contactez-nous", Component: ContactezNous },
    { path: "/evenements", Component: Events },
    { path: "/rapport-evenment", Component: RapportEvents },
    { path: "/rapport-evenment/:id", Component: DetailsRapports, exact: true },
    { path: "/evenements/:id", Component: Eventsdetails, exact: true },
    { path: "/activite", Component: Activities },
    { path: "/commissions-sante", Component: CommissionsScientifiques },
    { path: "/commissions-recherche", Component: CommissionsRecherche },
    { path: "/commissions-fomation", Component: CommeissionsFomation },
    { path: "/politique-de-confidentialite", Component: PrivacyPolicy },
    {
      path: "/commissions-sante-numerique",
      Component: CommissionEthiqueEtDeontologie,
    },
    { path: "/groupe-consultatif", Component: GroupeConsultatif },
    { path: "/cooperation-entreprise", Component: CooperationEntreprise },
    { path: "/ressources", Component: Ressources },
    { path: "/experts", Component: Expert },
    { path: "/apropos-de-nous", Component: AboutUs },
    { path: "/actualites", Component: Actualites },
    { path: "/actualites/details/:id", Component: NewDetails },
    { path: "/ressources/groupe-expert", Component: GroupeExpert },
    { path: "/ressources/renuon", Component: Renuon },
    {
      path: "/ressources/publications-et-rapports",
      Component: PublicationsRapports,
    },
    {
      path: "/ressources/bibliotheque",
      Component: BibliothequeRessources,
    },
    {
      path: "/ressources/organisations",
      Component: OrganisationsPartenaire,
    },
  ];

  return { publicUrl };
}
